<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import Loader from 'components/Loader.svelte'
  import { onMount } from 'svelte'
  import { getData } from 'utils/ApiUtils.svelte'
  import {
    convertSecToMin,
    formatNumberWith4Colors,
  } from 'utils/FormatUtils.svelte'

  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    currentExerciseId,
    currentExerciseName,
    currentRecordingId,
    userConfig
  } from '../../../components/DataStore'
  import StudentRecordingsDropDown from '../../../components/StudentRecordingsDropDown.svelte'
  import printJS from 'print-js'
  import { dynamicSort } from 'utils/SortingUtils.svelte'
  import TableHeaderItem from '../../../components/TableHeaderItem.svelte'
  import AutoComplete from 'simple-svelte-autocomplete'
  import PremiumIcon from '../../../components/visual/PremiumIcon.svelte'

  let recordingList = []
  let recordingListData = []
  let studentData = []
  let studentAccountData = []
  let optionGroupByNameSelectBestScore = false
  let optionAddMissingStudentsFromCourse = false
  let optionShowQrCodeStudents = true

  let studentNameList = []

  let filterStudent
  let sortColumn = 'createdAt'
  let sortDirection = -1

  userConfig.subscribe((v) => {
    if (v?.serverFlags?.qrHidden === true) {
      optionShowQrCodeStudents = false;
    }
  });

  function changeSort(e) {
    console.log(111, e.detail)
    sortColumn = e.detail.sortColumn
    sortDirection = e.detail.sortDirection

    recordingListStatus = 'loading'

    recordingList = recordingList.sort(dynamicSort(sortColumn, sortDirection))

    recordingListStatus = 'ok'
  }

  function changeFilter() {
    recordingListStatus = 'loading'

    filterRecordingData()

    recordingListStatus = 'ok'
  }

  onMount(async () => {
    getRecordingList($currentExerciseId)
    // loadStudents($currentCourseId)
    loadStudentAccounts($currentCourseId)
  })

  // function loadStudents(courseId) {
  //   getData('api/courses/' + courseId + '/studentEnrollment')
  //     .then((data) => {
  //       // console.log('courses - studentEnrollment successful', data)
  //       studentData = data
  //     })
  //     .catch((error) => {
  //       console.log('error courses', error)
  //     })
  // }

  function loadStudentAccounts(courseId) {
    getData('api/courses/' + courseId + '/student-accounts')
      .then((data) => {
        // console.log('courses - student-accounts successful', data)
        studentAccountData = data
        studentAccountData = studentAccountData.sort(
          dynamicSort('studentFirstName', 1)
        )
      })
      .catch((error) => {
        console.log('error courses - student-accounts', error)
      })
  }

  let recordingListStatus = 'loading'

  function clickChangePage(e) {
    console.log('clickChangePage', e)
    $currentPage = e
    router.goto('/' + e)
  }

  function reloadExerciseListButton() {
    mixpanel.track('Teacher Refresh Button Clicked', {
      'Button Location': 'Recordings Page',
    })
    recordingListStatus = 'loading'
    getRecordingList($currentExerciseId)
  }

  function printList() {
    mixpanel.track('Teacher Print Button Clicked', {
      'Button Location': 'Recordings Page',
    })
    console.log('printList recordingList', recordingList)
    printJS({
      printable: recordingList.map((e) => {
        const eOut = { ...e }
        if (!eOut?.teacherScore) {
          eOut['teacherScore'] = ''
        }
        if (eOut?.wordPerMinute) {
          eOut['wordPerMinute'] = eOut?.wordPerMinute.toFixed(0)
        }
        if (eOut?.createdAt) {
          eOut['createdAt'] = new Date(
            Date.parse(eOut?.createdAt + 'Z')
          ).toLocaleString()
        }
        return eOut
      }),
      type: 'json',
      properties: [
        { field: 'studentName', displayName: $_('STUDENT_NAME') },
        { field: 'teacherScore', displayName: $_('TEACHER_SCORE') },
        { field: 'overallScore', displayName: $_('OVERALL_SCORE') },
        {
          field: 'recordingDurationTimeSeconds',
          displayName: $_('RECORDING_TIME'),
        },
        { field: 'wordCount', displayName: $_('SPOKEN_WORD_COUNT') },
        { field: 'wordPerMinute', displayName: $_('WORDS_PER_MINUTE') },
        { field: 'createdAt', displayName: $_('DATE_AND_TIME_OF_RECORDING') },
      ],
      header: `<h3 class="custom-h3">${$_(
        'STUDENT_RECORDINGS'
      )}: ${$currentExerciseName}</h3><h5>${$currentCourseName} - ${$currentLessonName}</h5>`,
      style: '.custom-h3 { color: black; }',
    })
  }

  function removeItemAll(arr, value) {
    var i = 0
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1)
      } else {
        ++i
      }
    }
    return arr
  }

  function groupByStudentSelectBest(list) {
    //var cars = [{ make: 'audi', model: 'r8', year: 2012 }, { make: 'audi', model: 'rs5', year: 2013 }, { make: 'ford', model: 'mustang', year: 2012 }, { make: 'ford', model: 'fusion', year: 2015 }, { make: 'kia', model: 'optima', year: 2012 }];

    let result = list.reduce(function (r, a) {
      //console.log('r:',r);
      //console.log('a:',a);
      let x = r.find(({ studentName }) => studentName === a.studentName)
      if (!x) {
        r.push(a)
        return r
      }
      if (x && x.overallScore < a.overallScore) {
        removeItemAll(r, x)
        r.push(a)
      }
      return r
    }, [])

    console.log(result)

    return result
  }

  function addMissingStudentsFromCourse(data) {
    console.log('addMissingStudentsFromCourse', data)

    let uniqueNames = new Set()
    data.forEach((s) => {
      uniqueNames.add(s.studentName)
    })

    let courseNames = new Set()

    studentData.forEach((s) => {
      courseNames.add(s.studentName)
    })

    studentAccountData.forEach((s) => {
      courseNames.add(s.studentFirstName + ' ' + s.studentLastName)
    })

    console.log('courseNames', courseNames)

    courseNames.forEach((n) => {
      if (!uniqueNames.has(n)) {
        let x = {
          studentName: n,
          overallScore: undefined,
          createdAt: undefined,
          updatedAt: undefined,
          overallScore: 0,
          wordPerMinute: 0,
        }
        data.push(x)
      }
    })

    return data
  }

  function getRecordingList(exerciseId) {
    getData('api/exercises/' + exerciseId + '/publicrecordings')
      .then((data) => {
        // console.log('recording successful', data)

        recordingListData = data

        filterRecordingData()

        recordingListStatus = 'ok'
      })
      .catch((error) => {
        console.log('error recording', error)
        recordingListStatus = 'error'
      })
  }

  function filterRecordingData() {
    recordingList = recordingListData

    let studentNameSet = new Set()
    recordingListData.map((s) => {
      studentNameSet.add(s.studentName)
    })

    studentNameList = [...studentNameSet.values()].sort()


    if (!optionShowQrCodeStudents) {
      recordingList = recordingList.filter((e) => e.studentAccountLogin)
    }

    if (optionGroupByNameSelectBestScore) {
      recordingList = groupByStudentSelectBest(recordingList)
    } else {
      recordingList = recordingList
    }

    if (optionAddMissingStudentsFromCourse) {
      recordingList = addMissingStudentsFromCourse(recordingList)
    }

    

    if (filterStudent) {
      recordingList = recordingList.filter(
        (i) => i?.studentName === filterStudent
      )
    }
  }

  function openRecording(recordingId) {
    console.log('openRecording', recordingId)
    currentRecordingId.update((x) => recordingId)
    clickChangePage('recording-detail/' + $currentRecordingId)
  }

  function removeDuplicates(originalArray, prop) {
    var newArray = []
    var lookupObject = {}

    originalArray = originalArray.filter((element) => {
      return element !== undefined
    })

    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i]
    }

    for (let i in lookupObject) {
      newArray.push(lookupObject[i])
    }
    return newArray
  }

  function downloadList() {
    mixpanel.track('Teacher Export CSV Button Clicked', {
      'Button Location': 'Recordings Page',
    })

    const mimeType = 'data:text/csv;charset=utf-8'

    let items = recordingList.map(function (v) {
      let x = Object.assign({}, v)
      delete x.fieldName
      delete x.courseId
      delete x.lessonId
      delete x.exerciseId
      //delete x.recordingId;
      delete x.studentToken
      delete x.fileName
      delete x.accuracyScoreLabel
      delete x.fluencyScoreLabel
      delete x.completenessScoreLabel
      delete x.pronScoreLabel
      delete x.overallScoreLabel
      // delete x.accuracyScore;
      // delete x.completenessScore;
      // delete x.fluencyScore;
      // delete x.pronScore;
      // delete x.similarityValue;
      delete x.updatedAt

      x.overallScore = Number.parseInt(x?.overallScore)
      x.accuracyScore = Number.parseInt(x?.accuracyScore)
      x.completenessScore = Number.parseInt(x?.completenessScore)
      x.fluencyScore = Number.parseInt(x?.fluencyScore)
      x.pronScore = Number.parseInt(x?.pronScore)
      x.similarityValue = Number.parseInt(x?.similarityValue)
      x.wordPerMinute = Number.parseInt(x?.wordPerMinute)
      x.createdAt = x?.createdAt?.replaceAll('T', ' ')

      return x
    })

    const replacer = (key, value) => (value === null ? '' : value) // specify how you want to handle null values here
    //const header = Object.keys(items[0]);
    const header = [
      'id',
      'exerciseKey',
      'studentName',
      'overallScore',
      'recordingDurationTimeSeconds',
      'teacherScore',
      'teacherComment',
      'wordCount',
      'wordPerMinute',
      'createdAt',
    ]

    //id,exerciseKey,studentName,similarityValue,accuracyScore,fluencyScore,completenessScore,pronScore,overallScore,wordCount,recordingDurationTimeSeconds,wordPerMinute,teacherScore,teacherLabel,teacherComment,createdAt,updatedAt
    const csv = [
      header.join(','), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(',')
      ),
    ].join('\r\n')

    const d = new Date()

    var dummy = document.createElement('a')
    dummy.href = URL.createObjectURL(
      new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
        type: mimeType,
      })
    )

    dummy.download =
      'exercise-' +
      $currentExerciseName +
      '-results' +
      '-' +
      d.toISOString() +
      '.csv'
    dummy.click()
  }

  function formatDate(v) {
    return v ? new Date(Date.parse(v + 'Z')).toLocaleString() : ''
  }
</script>

<div class="card shadow">
  <div
    class="card-header py-3 no-print d-flex flex-row align-items-center justify-content-between"
  >
    <div>
      <button
        class="btn btn-primary s-button-primary s-mr-10px"
        on:click={reloadExerciseListButton}
        ><i class="fa fa-refresh" style="padding-right: 8px;" />{$_(
          'REFRESH'
        )}</button
      >
      <button
        class="btn btn-primary s-button-primary s-mr-10px"
        on:click={printList}
        ><i class="fa fa-print" style="padding-right: 8px;" />{$_('PRINT')}<br
        /></button
      >
      <button class="btn btn-primary s-button-primary" on:click={downloadList}
        ><i class="fa fa-download" style="padding-right: 8px;" />{$_(
          'EXPORT_CSV'
        )}</button
      >

      <div
        class="form-check form-check-inline ms-2"
        style="position: relative;"
      >
        <label class="form-check-label ms-3" for="inlineCheckbox1">
          <input
            class="form-check-input"
            type="checkbox"
            id="inlineCheckbox1"
            bind:checked={optionGroupByNameSelectBestScore}
            on:change={reloadExerciseListButton}
          />{$_('ONLY_BEST_SCORE')}</label
        >
      </div>
      <div class="form-check form-check-inline" style="position: relative;">
        <label class="form-check-label ms-3" for="inlineCheckbox2">
          <input
            class="form-check-input"
            type="checkbox"
            id="inlineCheckbox2"
            bind:checked={optionAddMissingStudentsFromCourse}
            on:change={reloadExerciseListButton}
          />
          {$_('ADD_MISSING_STUDENTS')}
        </label>
      </div>
      <div class="form-check form-check-inline" style="position: relative;">
        {#if $userConfig?.serverFlags?.qrHidden === true}
        <label class="form-check-label ms-3 text-secondary" for="inlineCheckbox3">
          <input
            class="form-check-input"
            type="checkbox"
            id="inlineCheckbox3"
            bind:checked={optionShowQrCodeStudents}
            on:change={reloadExerciseListButton}
            disabled
          />
          {$_('SHOW_QR_SHARE_STUDENTS')} <PremiumIcon></PremiumIcon>
        </label>
        {:else}
        <label class="form-check-label ms-3" for="inlineCheckbox3">
          <input
            class="form-check-input"
            type="checkbox"
            id="inlineCheckbox3"
            bind:checked={optionShowQrCodeStudents}
            on:change={reloadExerciseListButton}
          />
          {$_('SHOW_QR_SHARE_STUDENTS')}
        </label>
        {/if}
      </div>
    </div>
    <StudentRecordingsDropDown
      on:refresh={reloadExerciseListButton}
      on:print={printList}
      on:exportAllToCsv={downloadList}
    />
  </div>
  <div class="card-body">
    {#if recordingListStatus === 'loading'}
      <Loader />
    {:else}
      <div>
        <label for="studentNameSearch" class="mr-3">{$_('SEARCH_NAME')}</label>
        <AutoComplete
          items={studentNameList}
          showClear={true}
          bind:selectedItem={filterStudent}
          onChange={changeFilter}
        />
      </div>
      <div>
        <table class="table-responsive table mt-1">
          <thead>
            <th>#</th>
            <th>
              <TableHeaderItem
                column="studentName"
                {sortColumn}
                {sortDirection}
                on:changeSort={changeSort}>{$_('STUDENT_NAME')}</TableHeaderItem
              ></th
            >
            <th>
              <TableHeaderItem
                column="teacherScore"
                {sortColumn}
                {sortDirection}
                on:changeSort={changeSort}
                >{$_('TEACHER_SCORE')}</TableHeaderItem
              >
            </th>
            <th
              ><TableHeaderItem
                column="overallScore"
                {sortColumn}
                {sortDirection}
                on:changeSort={changeSort}
                >{$_('OVERALL_SCORE')}</TableHeaderItem
              ></th
            >
            <th
              ><TableHeaderItem
                column="recordingDurationTimeSeconds"
                {sortColumn}
                {sortDirection}
                on:changeSort={changeSort}
                >{$_('RECORDING_TIME')}</TableHeaderItem
              ></th
            >
            <th
              ><TableHeaderItem
                column="wordCount"
                {sortColumn}
                {sortDirection}
                on:changeSort={changeSort}
                >{$_('SPOKEN_WORD_COUNT')}</TableHeaderItem
              ></th
            >
            <th
              ><TableHeaderItem
                column="wordPerMinute"
                {sortColumn}
                {sortDirection}
                on:changeSort={changeSort}
                >{$_('WORDS_PER_MINUTE')}</TableHeaderItem
              ></th
            >
            <th
              ><TableHeaderItem
                column="createdAt"
                {sortColumn}
                {sortDirection}
                on:changeSort={changeSort}>{$_('CREATED_AT')}</TableHeaderItem
              ></th
            >
          </thead>
          <tbody>
            {#each recordingList as r, i}
              <tr>
                <td style="min-width: 3em;"
                  >{i + 1}<span class="ms-2">
                    <a href={`#/recording-detail/${r?.id}`} target="_blank"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                        width="1em"
                        height="1em"
                        ><path
                          d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z"
                        /></svg
                      ></a
                    ></span
                  ></td
                >
                <td
                  ><a href={`/#/recording-detail/${r.id}`}>
                    {#if r.studentAccountId}<strong>{r.studentName}</strong>{:else}{r.studentName}{/if}</a>
                </td>
                <td>{r.teacherScore ? r.teacherScore : ''}</td>
                <td>{formatNumberWith4Colors(r?.overallScore)}</td>
                <td>{convertSecToMin(r.recordingDurationTimeSeconds)}</td>
                <td>{r.wordCount ? r.wordCount : ''}</td>
                <td>{formatNumberWith4Colors(r.wordPerMinute)}</td>
                <td>{formatDate(r.createdAt)}</td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
      <!-- <div
        class="table-responsive table mt-1"
        id="dataTable"
        role="grid"
        aria-describedby="dataTable_info"
      >
        <SvelteTable
          bind:columns
          rows={recordingList}
          classNameRow="recordingRow"
          sortBy="createdAt"
          sortOrder="-1"
          on:clickRow={(event, row) => {
            console.log(event, event?.detail?.row)
            if (event?.detail?.event.ctrlKey) {
              console.log('handleCtrlEnter')
            } else {
              openRecording(event?.detail?.row?.id)
            }
          }}
        />
      </div> -->
    {/if}
  </div>
</div>

<style>
  :global(.recordingRow) {
    cursor: pointer;
  }
  :global(.recordingRow:hover) {
    background-color: whitesmoke;
  }
</style>
